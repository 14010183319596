import React, { useState } from 'react'
import InstaSlider from 'react-slick'
import instaIcon from "../assets/images/instagram-icon.svg"
import ShareYourStory from './shareYourStory'
import Insta1 from '../assets/images/instagram1.jpg'
import Insta2 from '../assets/images/instagram2.jpeg'
import Insta3 from '../assets/images/instagram3.jpeg'
import Insta4 from '../assets/images/instagram4.jpeg'

const instagramFeedStatic = () => {

    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const settings = {
        accessibility: false,
        nav: true,
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 2,
        rows: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    rows: 2,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };
    
    return (
        
        <>
        <div className="instagram-feed">
            <div className="container">
                <div className="insta-icon"> 
                    <img src={instaIcon} alt="Instagram icon" />
                </div>
                <h3>Helping Millions and Counting</h3>
                <div className="link-wrapper">
                    <a href={"https://www.instagram.com/" + "okeeffescompany"} target="_blank" rel="noopener noreferrer" className="link link--green">@{"okeeffescompany"}</a>
                </div>
                <div className="insta-grid">
                    <InstaSlider {...settings}>
                    
                                <div data-index="0" className="slick-slide slick-active slick-current" tabIndex="-1" aria-hidden="false" style={{outline: "none", width: "295px"}}>
                                    <div>
                                        <div className="insta-feed-block" tabIndex="-1" style={{width:"100%", display: "inline-block"}}>
                                            <a href="https://www.instagram.com/p/CJ8uqLjArlW" target="_blank" rel="noopener noreferrer">
                                                <img src={Insta1} alt="Today is National Pharmacist Day! 🔬 Did you know that Tara O'Keeffe, the creator of O'Keeffe's, was a pharmacist? She made O'Keeffe's Working Hands in her own kitchen to help her dad, Bill with his dry, cracked hands. #okeeffes #okeeffescompany #guaranteedrelief" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div data-index="1" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{outline: "none", width: "295px"}}>
                                    <div>
                                        <div className="insta-feed-block" tabIndex="-1" style={{width:"100%", display: "inline-block"}}>
                                            <a href="https://www.instagram.com/p/CJ6s1VLgi52" target="_blank" rel="noopener noreferrer">
                                                <img src={Insta2} alt="Which of our O'Keeffe's Lip Repair products can you not live without? Let us know below! #okeeffes #liprepair #drylips #chappedlips" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div data-index="2" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{outline: "none", width: "295px"}}>
                                    <div>
                                        <div className="insta-feed-block" tabIndex="-1" style={{width:"100%", display: "inline-block"}}>
                                            <a href="https://www.instagram.com/p/CJyQCeGrc_W" target="_blank" rel="noopener noreferrer">
                                                <img src={Insta3} alt="Your hands have been taking an extra beating with all the hand washing and sanitizing. Get them back to working condition with O'Keeffe's Working Hands.#dryskin #itchyskin #okeeffes #guaranteedrelief #handwashing" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div data-index="3" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{outline: "none", width: "295px"}}>
                                    <div>
                                        <div className="insta-feed-block" tabIndex="-1" style={{width:"100%", display: "inline-block"}}>
                                            <a href="https://www.instagram.com/p/CJtGc0CLBiq" target="_blank" rel="noopener noreferrer">
                                                <img src={Insta4} alt="Your hard working feet deserve some time off. Reward them with O'Keeffe's Healthy Feet! Guaranteed relief or your money back. #dryfeet #dryskin #itchyskin #okeeffes #guaranteedrelief" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            
                    </InstaSlider>
                </div>
                <div className="btn-wrapper">
                    <a href="#" onClick={() => onOpenModal()} className="btn btn--green btn--sm">{"Share Your Story"}</a>
                </div>
            </div>
        </div>
        <ShareYourStory open={open} onClose={onCloseModal} />
        </>
    )
}          
        
    


export default instagramFeedStatic
