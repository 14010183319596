import React, { useState } from 'react'
import { StaticQuery, graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import MobileSlider from 'react-slick'
import { getComplexData } from '../utils/getDataForLanguage'
import { get } from 'lodash'
import { getDataForLanguage } from '../utils/getDataForLanguage'

function SeeResults() {

    const settings = {
        accessibility: false,
        nav: true,
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500
    };

    const [activeTab, setActiveTab] = useState(0);

    function handleBeforeChange(oldIndex, newIndex) {
        setActiveTab(newIndex)
    }

    return (
        <StaticQuery
            query={graphql`
                query HomepageSeeResults {
                    allContentfulHomepage {
                        edges {
                          node {
                            seeResultsSectionBeforeTabText
                            seeResultsSectionAfterTabText
                            beforeafterResults {
                              afterImage {
                                file {
                                    url
                                }
                                title
                                node_locale
                                contentful_id
                              }
                              beforeImage {
                                file {
                                    url
                                }
                                title
                                node_locale
                                contentful_id
                              }
                              title
                              type
                              text {
                                  text
                              }
                              node_locale
                              contentful_id
                            }
                            node_locale
                            contentful_id
                          }
                        }
                      }
                }`
            }
            render={(data) => {

                const beforeAfterResults = data.allContentfulHomepage.edges;
                let filteredBeforeAfterResults = getComplexData(beforeAfterResults.map(item => item.node), process.env.COUNTRY, "beforeafterResults");

                const dataLang = getDataForLanguage(beforeAfterResults.map(item => item.node), process.env.COUNTRY);
                const afterText = get(dataLang, '[0].seeResultsSectionAfterTabText','After');
                const beforeText = get(dataLang, '[0].seeResultsSectionBeforeTabText','Before');



                filteredBeforeAfterResults = filteredBeforeAfterResults[0];
                const colors = ['green', 'blue', 'orange', 'yellow'];


                if (beforeAfterResults.length > 0) {
                    return (
                        <div className="see-results">
                            <div className="container-lg">
                                <div className="tabs-wrapper tabs-wrapper--desktop">
                                    <div className="see-results-content">
                                        <Tabs defaultFocus={true} onSelect={index => setActiveTab(index)}>
                                            {filteredBeforeAfterResults.map((y, key) => {
                                                return (
                                                    <TabPanel key={key}>
                                                        <h3>{y.title}</h3>
                                                        <p>{y.text.text}</p>
                                                    </TabPanel>
                                                )
                                            })}

                                            <TabList>
                                                {filteredBeforeAfterResults.map((i, key) => {
                                                    return (
                                                        <Tab tabIndex="0" key={key} className={"react-tabs__tab react-tabs__tab--" + colors[key]}>{i.type}</Tab>
                                                    )
                                                })}
                                            </TabList>
                                        </Tabs>
                                    </div>

                                    <div className="before-after-tabs">
                                        <Tabs defaultFocus={true} selectedIndex={activeTab} onSelect={() => { }}>
                                            <TabList style={{ display: 'none' }}>
                                                {filteredBeforeAfterResults.map((i, key) => {
                                                    return (
                                                        <Tab tabIndex="0" key={key} className={"react-tabs__tab react-tabs__tab--" + colors[key]}>{i.type}</Tab>
                                                    )
                                                })}
                                            </TabList>

                                            {filteredBeforeAfterResults.map((item, key) => {
                                                return (
                                                    <TabPanel key={key} className={"tab-item-" + key}>
                                                        <div className={"before-after-showcase colored-" + colors[key]}>
                                                            <Tabs defaultFocus={true}>
                                                                <TabList>
                                                                    <Tab tabIndex="0" className="react-tabs__tab">{beforeText}</Tab>
                                                                    <Tab tabIndex="0" className="react-tabs__tab">{afterText}</Tab>
                                                                </TabList>
                                                                <TabPanel>
                                                                    <div className="showcase-image showcase-image--before">
                                                                        <img src={get(item, 'beforeImage.file.url', '')} alt={get(item, 'beforeImage.title', '')} />
                                                                    </div>
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <div className="showcase-image showcase-image--after">
                                                                        <img src={get(item, 'afterImage.file.url', '')} alt={get(item, 'afterImage.title', '')} />
                                                                    </div>
                                                                </TabPanel>
                                                            </Tabs>
                                                        </div>
                                                    </TabPanel>
                                                )
                                            })}
                                        </Tabs>
                                    </div>
                                </div>


                                <div className="tabs-wrapper--mobile before-after-tabs">
                                    <MobileSlider {...settings} beforeChange={handleBeforeChange}>
                                        {filteredBeforeAfterResults.map((m, key) => {
                                            return (
                                                <div key={key} className="before-after-showcase">
                                                    <div className={"before-after-showcase colored-" + colors[key]}>
                                                        <h3 className="title">{m.title}</h3>
                                                        <div className="text">{m.text.text}</div>
                                                        <div className="react-tabs__tab-list">
                                                            <div className="react-tabs__tab react-tabs__tab--selected">{m.type}</div>
                                                        </div>
                                                        <Tabs defaultFocus={true} selectedIndex={activeTab} onSelect={() => { }}>
                                                            <TabList style={{ display: 'none' }}>
                                                                {filteredBeforeAfterResults.map((i, key) => {
                                                                    return (
                                                                        <Tab tabIndex="0" key={key} className={"react-tabs__tab react-tabs__tab--" + colors[key]}>{i.type}</Tab>
                                                                    )
                                                                })}
                                                            </TabList>

                                                            {filteredBeforeAfterResults.map((item, key) => {
                                                                let baseClass = "tab-item-" + key;
                                                                if(key === activeTab) baseClass += "react-tabs__tab-panel--selected";

                                                                return (
                                                                    <TabPanel key={key} className={baseClass}>
                                                                        <Tabs defaultFocus={true}>
                                                                            <TabList>
                                                                                <Tab tabIndex="0" className="react-tabs__tab">{beforeText}</Tab>
                                                                                <Tab tabIndex="0" className="react-tabs__tab">{afterText}</Tab>
                                                                            </TabList>
                                                                            <TabPanel>
                                                                                <div className="showcase-image showcase-image--before">
                                                                                    <img src={get(item, 'beforeImage.file.url', '')} alt={get(item, 'beforeImage.title', '')} />
                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel>
                                                                                <div className="showcase-image showcase-image--after">
                                                                                    <img src={get(item, 'afterImage.file.url', '')} alt={get(item, 'afterImage.title', '')} />
                                                                                </div>
                                                                            </TabPanel>
                                                                        </Tabs>
                                                                    </TabPanel>
                                                                )
                                                            })}
                                                        </Tabs>
                                                        
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </MobileSlider>
                                </div>
                            </div>
                        </div>
                    )
                }
                return null;
            }}
        />
    )
}

export default SeeResults