import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import { getDataForLanguage } from '../utils/getDataForLanguage'
import { get } from 'lodash'
import Slider from 'react-slick'

export class HeroSlider extends Component {
    
    render() {

        const settings = {
            accessibility: false,
            nav: true,
            dots: true,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        
        return(
            <StaticQuery
                query={graphql`
                    query FrontPageSlider {
                        allContentfulFrontPageSlider(sort: {fields: order}) {
                            edges {
                                node {
                                    text {
                                        content {
                                            content {
                                                value
                                            }
                                        }
                                    }
                                    srOnlyText
                                    secondaryText
                                    backgroundImage {
                                        file {
                                            fileName
                                            url
                                        }
                                        title
                                        node_locale
                                        contentful_id
                                    }
                                    image {
                                        file {
                                            fileName
                                            url
                                        }
                                        title
                                        node_locale
                                        contentful_id
                                    }
                                    linkPath
                                    buttonText
                                    type {
                                        title
                                    }
                                    node_locale
                                    contentful_id
                                }
                            }
                        }
                    }`
                }
                render={(data) => {
                    const allPageData = get(data, "allContentfulFrontPageSlider.edges", '');
                    let pageData = getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY);
                    return(
                        <div className="hero" id="maincontent">
                            <Slider {...settings}>
                                {pageData.map((item, index) => {
                                    
                                    var styles = {
                                        backgroundImage: item.backgroundImage ? 'url(' + item.backgroundImage.file.url + ')' : 'none',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height:'100%'
                                    }
                                    return (
                                        <div key={item.contentful_id} className={"slide__inner slide__inner--" + item.type.title} >
                                            <div className="prod-background-image" style={styles}>
                                            <div className="container pos-rel"> 
                                                <div className="slide__inner--content">
                                                    {index == 0 ? 
                                                        <h1>{item.text.content.map(({content},key) => {
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    {content.map(({value}) => {
                                                                        return (
                                                                            <span key={value}>{value}</span>
                                                                        )
                                                                    })}
                                                                </React.Fragment>
                                                            )
                                                        })}</h1> 
                                                    :
                                                    <h2>{item.text.content.map(({content},key) => {
                                                        return (
                                                            <React.Fragment key={key}>
                                                                {content.map(({value}) => {
                                                                    return (
                                                                        <span key={value}>{value}</span>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )
                                                    })}</h2> 
                                                    }
                                                    
                                                    <span><strong>{item.secondaryText}</strong></span>
                                                    <Link to={item.linkPath} className="btn btn--primary btn--sm">{get(item,'buttonText','Learn More')}<span className="sr-only"> {item.srOnlyText}</span></Link>
                                                </div>
                                                <img className="prod-thumbnail-lg" src={item.image.file.url} alt={item.image.title} />
                                            </div>
                                            <div className="link-container">
                                                <Link to={item.linkPath} className="btn btn--primary btn--sm">{get(item,'buttonText','Learn More')}<span className="sr-only"> {item.srOnlyText}</span></Link>
                                            </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    )
                }}
            />
        )
    }
}

export default HeroSlider
