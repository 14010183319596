import React from 'react'
import get from 'lodash/get'
import { Link } from "gatsby"
import PropTypes from 'prop-types';

const BodyBox = (props) => {
    const color = props.color;
    const content = props.content;
    const buttonText = get(props, 'buttonText', 'Learn More');
    const image = get(content, 'collectionImage.file.url', '');
    const imageName = "Main image for category: " + get(content, 'title', '');
    let style = {
        backgroundImage: "url(" + image + ")"
    }
    const linkTitle = "about " + get(content, 'title', '')+ " products";
    return (
        <div className="body-block-outer">
            <div className={"body-block body-block--" + color} style={style}>
                <img className="body-block__image img-fluid" src={get(content, 'collectionImage.file.url', '')} alt={imageName}/>
                <div className="block-inner">
                    <div className="block-content">
                        <h3>{get(content, 'title', '')}</h3>
                        <p>{get(content, 'description.description', '')}</p>
                        <Link to="/products" title={linkTitle} state={{ activeTab: props.category }} className="btn btn--dark btn--sm">{buttonText} <span className="sr-only">{linkTitle}</span></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

BodyBox.propTypes = {
    color: PropTypes.string,
    content: PropTypes.any,
    category: PropTypes.number
}

export default BodyBox
