import React, { Component } from 'react'
import Layout from "../components/layout";
import HeroSlider from "../components/heroSlider";
import BodyBoxes from "../components/bodyBoxes";
import Testimonials from "../components/testimonials";
import SeeResults from "../components/seeResults";
import InstagramFeedStatic from "../components/instagramFeedStatic";
import { graphql } from 'gatsby'
import { get, first } from 'lodash'
import { getDataForLanguage} from '../utils/getDataForLanguage'

export class IndexPage extends Component {
    render() {

        const allPageData = get(this, "props.data.allContentfulSiteInformations.edges", '');
        let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));


        const allPageDataForSections = get(this, "props.data.allContentfulHomepage.edges", '');
        let pageDataforSections = first(getDataForLanguage(allPageDataForSections.map(item => item.node), process.env.COUNTRY));

        return (
            <Layout 
                title={get(pageData, 'indexPageMetaTitle','')} 
                description={get(pageData, 'indexPageMetaDescription','')}
                pathName="/"
            >
                {pageDataforSections.enableSlideshow && <HeroSlider />}
                {pageDataforSections.enableCollectionsSection && <BodyBoxes />}
                {pageDataforSections.enableTestimonialsSection && <Testimonials />}
                {pageDataforSections.enableSeeResultsSection && <SeeResults />}
                {pageDataforSections.enableInstagramFeedSection && <InstagramFeedStatic />}
                
                
            </Layout>
        )
    }
}

export default IndexPage

export const pageQuery = graphql`
    query SiteInfoForFrontPage {
        allContentfulSiteInformations {
            edges {
                node {
                    indexPageMetaTitle
                    indexPageMetaDescription
                    node_locale
                    contentful_id
                }
            }
        }
        allContentfulHomepage {
            edges {
                node {
                    enableSlideshow
                    enableCollectionsSection
                    enableSeeResultsSection
                    enableTestimonialsSection
                    enableInstagramFeedSection
                    node_locale
                    contentful_id
                }
            }
        }
    }
`
