import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import get from 'lodash/get'
import TestimonialsSlider from 'react-slick'
import { getComplexData } from '../utils/getDataForLanguage'

const testimonials = () => {
    const settings = {
        accessibility: false,
        nav: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        speed: 500,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            },
            {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
            }
        ]
    };
    return (
        <StaticQuery
            query={graphql`
              query HomepageTestimonials {
                allContentfulHomepage {
                  edges {
                    node {
                      testimonials {
                        text {
                          text
                        }
                        author
                        node_locale
                        contentful_id
                      }
                    node_locale
                    }
                  }
                }
              }`
            }
            render={(data) => {

                const testimonials = data.allContentfulHomepage.edges;
                const filteredTestimonials = getComplexData(testimonials.map(item => item.node), process.env.COUNTRY, "testimonials");

                if (testimonials.length > 0) {
                    return(
                        <div className="testimonials">
                            <div className="container-lg">
                                <TestimonialsSlider {...settings}>
                                    {filteredTestimonials[0].map((t, key) => {
                                        return (
                                            <div key={key} className="item testimonial">
                                                <div className="testimonial--content">
                                                    <p>{get(t, 'text.text', '')}</p>
                                                    <span className="testimonial--content__by">- {get(t, 'author', '')}</span>
                                                </div>
                                            </div>
                                        )
                                    })}   
                                </TestimonialsSlider>
                            </div>
                        </div> 
                    )
                }
                return null;
            }}
            />
    )
}

export default testimonials
