import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { get } from 'lodash'
import BodyBox from './bodyBox';
import { getComplexData } from '../utils/getDataForLanguage'

const bodyBoxes = () => {
    return (
        <StaticQuery
            query={graphql`
                query HomepageBodyBoxes {
                    allContentfulHomepage {
                        edges {
                            node {
                                collections {
                                    title
                                    description {
                                        description
                                    }
                                    collectionImage {
                                        file {
                                            url
                                        }
                                        title
                                        node_locale
                                        contentful_id
                                    }
                                    buttonText
                                    node_locale
                                    contentful_id
                                    }
                                node_locale
                            }
                        } 
                    }
                }`
            }

            render={(data) => {

                const allCollections = data.allContentfulHomepage.edges;
                const filteredCollections = getComplexData(allCollections.map(item => item.node), process.env.COUNTRY, "collections");
                const collections = filteredCollections[0];

                if (collections.length > 0) {
                    return (
                        <div className="body-boxes">
                            <div className="boxes-wrapper">
                                <div className="boxes-inner">
                                    <div className="w-33">
                                        <BodyBox color="green" content={get(collections, '[0]', '')} category={1} />
                                    </div>
                                    <div className="w-33 has-two-blocks">
                                        <BodyBox color="orange" content={get(collections, '[1]', '')} category={3} />
                                        <BodyBox color="yellow" content={get(collections, '[2]', '')} category={4} />
                                    </div>
                                    <div className="w-33">
                                        <BodyBox color="blue" content={get(collections, '[3]', '')} category={2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                return null;
            }}
        />
    )
}

export default bodyBoxes
